//@ts-nocheck
import axios from 'axios';
import { HistoryItem, SRT, User, RecordResponse } from '../types/type';
import { SetStateAction } from 'react';

const convertSecondsToTime = (seconds) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = Math.floor(seconds % 60);
  return [hours, minutes, secs].map((val) => val.toString().padStart(2, '0')).join(':');
};

const convertTimeToSeconds = (time) => {
  const [hours, minutes, seconds] = time.split(':');
  return parseInt(hours) * 3600 + parseInt(minutes) * 60 + parseFloat(seconds.replace(',', '.'));
};

const getTranscript = async (textUrl) => {
//   console.log(`Fetching SRT from: ${textUrl}`);
  const srtResponse = await axios.get(textUrl);
//   console.log('SRT data fetched successfully.');

  let timeOffset = 0; // Initialize time offset
  let firstSegmentProcessed = false; // Track if the first segment index 1 has been processed

  let srt = srtResponse.data.split(/\n{2,}/).map((block, blockIndex) => {
    const lines = block.split('\n');
    // console.log(`Processing block #${blockIndex + 1}`);

    const indexLine = parseInt(lines[0]);
    if (isNaN(indexLine)) {
    //   console.log(`Block #${blockIndex + 1} skipped: index line is not a number`);
      return null; // Skip blocks that don't start with an index number
    }

    if (indexLine === 1 && firstSegmentProcessed) {
    //   console.log(`New segment starts at block #${blockIndex + 1}, adding time offset`);
      // Increment time offset by 9:55 minutes for new segment
      timeOffset += 595; // Updated offset value
    } else if (indexLine === 1) {
      firstSegmentProcessed = true;
    //   console.log(`First segment starts at block #${blockIndex + 1}, no time offset added`);
    }

    // console.log(`Applying time offset: ${timeOffset} seconds`);

    const timestampMatch = lines[1].match(/(\d{2}:\d{2}:\d{2},\d{3}) --> (\d{2}:\d{2}:\d{2},\d{3})/);
    if (!timestampMatch) {
    //   console.log(`Block #${blockIndex + 1} skipped: no timestamp match`);
      return null; // Skip this block if there's no time match
    }

    const startTime = convertTimeToSeconds(timestampMatch[1]) + timeOffset;
    const endTime = convertTimeToSeconds(timestampMatch[2]) + timeOffset;

    const startLabel = convertSecondsToTime(startTime);
    const endLabel = convertSecondsToTime(endTime);

    lines.splice(0, 2);
    const text = lines.join('\n').trim();

    // console.log(`Block #${blockIndex + 1} label: ${startLabel}`);
    // console.log(`Block #${blockIndex + 1} text: ${text}`);

    return {
      time: Math.round(startTime),
      label: startLabel,
      text,
      selected: false
    };
  }).filter((obj) => obj !== null);

  console.log('SRT parsing completed.');
  return srt;
};

const uploadToNokcci = async (file: File[], user: User | null, history: HistoryItem[], setHistory:React.Dispatch<SetStateAction<HistoryItem[]>>) => {
    if (user) {
        const formData = new FormData();
        formData.append('audio_file', file[0]);
        formData.append('user', user.id)
        try {
            const response = await axios.post("/uploadNokcci", formData);
            const { text: textUrl, audio: audioUrl, nokcci: new_history } = response.data;
            setHistory([...history, new_history]);
            // Fetch the SRT content from the provided URL
            let srt = await getTranscript(textUrl);
            let content = { srt: srt, audio: audioUrl };
            return content;
        } catch (error) {
            console.log(error);
            let srt = [{
                time: 0,
                label: "0:00",
                text: "There was an error in sending a file",
                selected: false,
            },]
            let content = { srt: srt, audio: '' }
            return content;
        }
    } else {
        let srt = [{
                time: 0,
                label: "0:00",
                text: "Impossible Transaction. Please login or signup",
                selected: false,
            },]
            let content = { srt: srt, audio: '' }
        return content;
    }
}

const getRecord = async (historyItem: HistoryItem, user: User | null) => {
    if (user) {
        const formData = new FormData();
        formData.append('user_id', user.id)
        formData.append('nokcci_path', historyItem.nokcci);
        formData.append('transcript_path', historyItem.transcript)
        try {
            const response = await axios.post("/getRecord", formData);
            return response.data;
        } catch (error) {
            let response = {"nokcci":"error", "transcript":''}
            return response;
        }
    } else {
        let response = {"nokcci":"error", "transcript":''}
            return response;
    }
}
export { getTranscript, uploadToNokcci, getRecord };