import React, { SetStateAction, useCallback, useEffect } from "react";
import TranscriptionActions from "./TranscriptionActions";
import type { SRT, AudioMeta } from "../types/type";
import * as Hangul from "hangul-js";
import lottie, { AnimationItem } from "lottie-web";
import loadingAnimation from "../public/loading_wave.json";

interface TranscriptionScroll {
	srt: SRT[];
	audioRef: React.RefObject<HTMLAudioElement>;
	handleTimestampClick: (timestamp: number) => void;
	search: string;
	setSearch: React.Dispatch<SetStateAction<string>>;
	filteredTimestamp: SRT[] | null;
	setFilteredTimestamp: React.Dispatch<SetStateAction<SRT[] | null>>;
	loading: boolean;
	showSelected: boolean;
	setShowSelected: React.Dispatch<SetStateAction<boolean>>;
	audioMeta: AudioMeta | null;
}

const TranscriptionScroll: React.FC<TranscriptionScroll> = ({
	srt,
	handleTimestampClick,
	search,
	setSearch,
	filteredTimestamp,
	setFilteredTimestamp,
	loading,
	showSelected,
	setShowSelected,
	audioMeta,
}) => {
	const handleCheckboxClick = (index: number) => {
		if (filteredTimestamp) {
			const updatedTimestamps = [...filteredTimestamp]; // Create a copy of the array
			updatedTimestamps[index].selected = !updatedTimestamps[index].selected; // Toggle the selected value
			setFilteredTimestamp(updatedTimestamps); // Update the state
		}
	};

	const filterTimestamps = useCallback(() => {
		if (srt) {
			const filtered = srt.filter(({ text }) => {
				if (!search.trim()) {
					return true; // If searchTerm is empty, return everything
				}
				const disassembledText = Hangul.disassemble(text, true);
				const disassembledSearchTerm = Hangul.disassemble(search, true);
				const searchResult = Hangul.search(
					disassembledText.join(),
					disassembledSearchTerm.join()
				);
				return searchResult >= 0;
			});
			setFilteredTimestamp(filtered);
		}
	}, [srt, search]);

	const getHighlightedText = (text: string, highlight: string) => {
		// Split text on highlight term, include term itself into parts, ignore case
		const parts = text.split(new RegExp(`(${highlight})`, "gi"));
		return (
			<span>
				{parts.map((part, i) => (
					<span key={i} className={part === highlight ? "highlight" : ""}>
						{part}
					</span>
				))}
			</span>
		);
	};

	let container: HTMLDivElement | null = document.querySelector(
		"#loading-animation-container"
	);

	let animation: AnimationItem | null = null;

	useEffect(() => {
		if (container && loading) {
			animation = lottie.loadAnimation({
				container: container,
				renderer: "svg",
				loop: true,
				autoplay: true,
				animationData: loadingAnimation,
				rendererSettings: {
					preserveAspectRatio: "xMidYMid meet",
				},
			});
		}
		return () => {
			if (animation) {
				animation.destroy();
			}
		};
	}, [loading]);

	useEffect(() => {
		filterTimestamps();
	}, [search, filterTimestamps]);

	return (
		<div className="frames">
			<div className="inputC">
				<input
					type="text"
					value={search}
					onChange={(e) => setSearch(e.target.value)}
					placeholder="찾기..."
				/>
				<TranscriptionActions
					filteredTimestamp={filteredTimestamp}
					audioMeta={audioMeta}
					showSelected={showSelected}
					setShowSelected={setShowSelected}
					loading={loading}
				/>
			</div>
			<div className="scrollable_window">
				<div
					id="loading-animation-container"
					style={{
						width: "200px",
						height: "200px",
						alignSelf: "center",
						display: loading ? "block" : "none",
					}}
				></div>
				{filteredTimestamp && !loading ? (
					filteredTimestamp
						.filter(({ selected }) => (showSelected ? selected : true))
						.map(({ text, label, time, selected }, index: number) => (
							<div key={time} className="column">
								<button
									onClick={() => handleTimestampClick(time)}
									title={String(time)}
								>
									{label}
								</button>
								{text ? (
									<p className="transcriptions">
										{getHighlightedText(text, search)}
									</p>
								) : (
									<p>No text found</p>
								)}
								<input
									type="checkbox"
									checked={selected}
									onClick={() => handleCheckboxClick(index)}
								/>
							</div>
						))
				) : (
					<div />
				)}
			</div>
		</div>
	);
};

export default TranscriptionScroll;
