//@ts-nocheck
import "./App.css";
import React, { useState, useEffect } from "react";
import NokcciPage from "./pages/NokcciPage";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import { sendGoogleRequest } from "./api/NokcciBackend";
import "bootstrap/dist/css/bootstrap.min.css";
import Cookies from "js-cookie";
import axios from "axios";

function App() {
	const [user, setUser] = useState(null);
	const [history, setHistory] = useState([]);
	const relogUser = async (userObj) => {
		try {
			const response = await axios.post("/relog", {
				id: userObj.id.toString(),
			});
			updateUserData(response.data.user, response.data.nokcci_history);
		} catch (error) {
			console.error("Relogin failed: ", error);
		}
	};
	useEffect(() => {
		const userData = Cookies.get("user");
		if (userData) {
			var user = JSON.parse(userData);
			relogUser(user);
		}
	}, []);

	const logOut = () => {
		alert("button clicked");
		googleLogout();
		setUser(null);
		Cookies.remove("user");
	};

	const updateUserData = (user, history) => {
		setUser(user); // Set the user state.
		setHistory(history); // Set the history state.
		Cookies.set("user", JSON.stringify(user), { expires: 1 }); // Save user data in cookie for 1 day
	};

	const sendSignup = (codeResponse) => {
		sendGoogleRequest("/signup", {
			access_token: codeResponse.access_token,
		})
			.then((response) => {
				updateUserData(response.user, response.nokcci_history);
			})
			.catch((err) => {
				alert(err.detail);
			});
	};

	const sendLogin = (codeResponse) => {
		sendGoogleRequest("/login", {
			access_token: codeResponse.access_token,
		})
			.then((res) => {
				console.log("Response received:", res);
				updateUserData(res.data.user, res.data.nokcci_history);
			})
			.catch((err) => {
				alert(err.detail);
			});
	};

	const login = useGoogleLogin({
		onSuccess: (codeResponse) => sendLogin(codeResponse),
		onError: (error) => {
			setUser(null);
			alert(error.detail);
		},
	});

	const signup = useGoogleLogin({
		onSuccess: (codeResponse) => sendSignup(codeResponse),
		onError: (error) => {
			setUser(null);
			alert(error.detail);
		},
	});

	useEffect(() => {
		console.log("User:", user);
		console.log("History:", history);
	}, [user, history]);

	return (
		<div className="App">
			{user ? (
				<NokcciPage
					logOut={logOut}
					history={history}
					setHistory={setHistory}
					user={user}
				/>
			) : (
				<section
					className="vh-100"
					style={{
						backgroundImage: `url('/audiototext.png')`,
						backgroundSize: "cover", // Ensure the image covers the entire section
						backgroundPosition: "center", // Center the background image
					}}
				>
					<div className="container py-5 h-100">
						<div className="row d-flex justify-content-center align-items-center h-100">
							<div className="col-12 col-md-8 col-lg-6 col-xl-5">
								<div
									className="card shadow-2-strong"
									style={{ border: "none" }}
								>
									<div className="card-body p-5 text-center">
										<h3 className="mb-5">녹취(NOKCCI) 로그인</h3>

										{/* <div className="form-outline mb-4">
											<input
												type="email"
												id="typeEmailX-2"
												className="form-control form-control-lg"
											/>
											<label className="form-label" htmlFor="typeEmailX-2">
												Email
											</label>
										</div>

										<div className="form-outline mb-4">
											<input
												type="password"
												id="typePasswordX-2"
												className="form-control form-control-lg"
											/>
											<label className="form-label" htmlFor="typePasswordX-2">
												Password
											</label>
										</div>
										<div className="form-check d-flex justify-content-start mb-4">
											<input
												className="form-check-input"
												type="checkbox"
												value=""
												id="form1Example3"
											/>
											<label
												className="form-check-label"
												htmlFor="form1Example3"
											>
												{" "}
												Remember password{" "}
											</label>
										</div>

										<button
											className="btn btn-primary btn-lg btn-block"
											type="submit"
											style={{ width: "100%" }}
											onClick={() => {
												login();
											}}
										>
											Login
										</button> */}

										<hr className="my-4" />

										<button
											className="btn btn-lg btn-block btn-outline-primary"
											style={{
												width: "100%",
												marginBottom: "2rem",
											}}
											type="submit"
											onClick={() => {
												login();
											}}
										>
											<i className="fab fa-google me-2"></i> Google 계정 로그인
										</button>
										<button
											className="btn btn-lg btn-block btn-primary mb-2"
											style={{ width: "100%" }}
											onClick={() => {
												signup();
											}}
										>
											<i className="fab fa-google me-2"></i> Google 계정으로
											회원 가입
										</button>
										<a href="https://www.freeprivacypolicy.com/live/0fe1276c-420f-4488-9c2a-13a8953a68ab">
											{" "}
											privacy policy
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			)}
		</div>
	);
}

export default App;
