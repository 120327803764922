import React, { SetStateAction } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCopy,
	faDownload,
	faCheckSquare,
	faSquare,
} from "@fortawesome/free-solid-svg-icons";
import type { SRT, AudioMeta } from "../types/type";

interface TranscriptionActions {
	filteredTimestamp: SRT[] | null;
	audioMeta: AudioMeta | null;
	showSelected: boolean;
	setShowSelected: React.Dispatch<SetStateAction<boolean>>;
	loading: boolean;
}

const TranscriptionActions: React.FC<TranscriptionActions> = ({
	filteredTimestamp,
	audioMeta,
	showSelected,
	setShowSelected,
	loading,
}) => {
	const handleClick = () => {
		setShowSelected(!showSelected);
	};

	const handleCopyTimestamps = () => {
		if (!filteredTimestamp || filteredTimestamp.length === 0) {
			alert("There are no timestamps to copy");
			return;
		}
		let timestamps: string | undefined = undefined;
		if (!showSelected) {
			timestamps = filteredTimestamp
				.map(({ time, label, text }) => `${label}\n${text}`)
				.join("\n\n");
		} else {
			timestamps = filteredTimestamp
				.filter(({ selected }) => (showSelected ? selected : true))
				.map(({ time, label, text }) => `${label}\n${text}`)
				.join("\n\n");
		}
		if (!timestamps) {
			alert("There is no text to copy");
			return;
		}
		if (!document.hasFocus()) {
			alert("Please make sure the document is focused");
			return;
		}
		navigator.clipboard
			.writeText(timestamps)
			.then(() => {
				alert("Copied!");
			})
			.catch((error) => {
				console.error("Failed to copy:", error);
			});
	};

	const handleExportTimestamps = () => {
		if (!filteredTimestamp || filteredTimestamp.length === 0) {
			alert("There are no timestamps to export");
			return;
		}
		let timestamps: string | undefined = undefined;
		if (!showSelected) {
			timestamps = filteredTimestamp
				.map(({ time, label, text }) => `${label}\n${text}`)
				.join("\n\n");
		} else {
			timestamps = filteredTimestamp
				.filter(({ selected }) => (showSelected ? selected : true))
				.map(({ time, label, text }) => `${label}\n${text}`)
				.join("\n\n");
		}
		if (!timestamps) {
			alert("There is no exportable text");
			return;
		}
		const element = document.createElement("a");
		const file = new Blob([timestamps], { type: "text/plain" });
		element.href = URL.createObjectURL(file);
		element.download = audioMeta
			? audioMeta.name + "_transcript.txt"
			: "noName_transcript.txt";
		document.body.appendChild(element);
		element.click();
	};

	return (
		<div className="div">
			<button className="buttons" onClick={handleClick} title="고른거만 보기">
				{showSelected ? (
					<FontAwesomeIcon icon={faCheckSquare} />
				) : (
					<FontAwesomeIcon icon={faSquare} />
				)}
			</button>
			<button className="buttons" onClick={handleCopyTimestamps} title="복사">
				<FontAwesomeIcon icon={faCopy} />
			</button>
			<button
				className="buttons"
				onClick={handleExportTimestamps}
				title="내보내기"
			>
				<FontAwesomeIcon icon={faDownload} />
			</button>
		</div>
	);
};

export default TranscriptionActions;
