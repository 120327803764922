import "../App.css";
import React, { useState, useRef, SetStateAction } from "react";
import type { AudioMeta, SRT, HistoryItem, User } from "../types/type";

// import components that appear on home page
import NokcciDropzone from "../components/Dropzone";
import TranscriptionScroll from "../components/TranscriptionScroll";
import HistoryTab from "../components/HistoryTab";

// import icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";

type LogOutFunction = () => void;

// accept react-google-auth logout function
const NokcciPage: React.FC<{
	logOut: LogOutFunction;
	history: HistoryItem[];
	setHistory: React.Dispatch<SetStateAction<HistoryItem[]>>;
	user: User | null;
}> = ({ logOut, history, setHistory, user }) => {
	// declare state variables
	const [audioFile, setAudioFile] = useState<string | null>(null); // currently selected audio file
	const [audioMeta, setAudioMeta] = useState<AudioMeta | null>(null); // the metadata of currently selected audio file
	const [srt, setSrt] = useState<SRT[]>([
		{
			time: 0,
			label: "0:00",
			text: "Upload a video to get a transcript",
			selected: false,
		},
	]);
	const [search, setSearch] = useState<string>("");
	const [filterdTimestamp, setFilteredTimestamp] = useState<SRT[] | null>(null);
	const [showSelected, setShowSelected] = useState(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [audioUrl, setAudioUrl] = useState("");

	const audioRef = useRef<HTMLAudioElement>(null);

	const handleTimestampClick = (timestamp: number) => {
		if (audioRef.current) {
			audioRef.current.currentTime = timestamp;
			if (audioRef.current.paused) {
				audioRef.current.play();
			}
		}
	};

	return (
		<>
			<div className="profile"></div>

			<div className="layout">
				<HistoryTab
					setSrt={setSrt}
					history={history}
					setAudioMeta={setAudioMeta}
					setAudioUrl={setAudioUrl}
					user={user}
				/>
				<h1>인공지능(AI) 녹취 변환 서비스</h1>
				<div className="full">
					<div className="audio">
						<NokcciDropzone
							audioFile={audioFile}
							setAudioFile={setAudioFile}
							audioMeta={audioMeta}
							setAudioMeta={setAudioMeta}
							setSrt={setSrt}
							audioRef={audioRef}
							setLoading={setLoading}
							audioUrl={audioUrl}
							setAudioUrl={setAudioUrl}
							user={user}
							history={history}
							setHistory={setHistory}
						/>
					</div>
					<FontAwesomeIcon icon={faArrowAltCircleRight} className="arrow" />
					<div className="transcript">
						<TranscriptionScroll
							srt={srt}
							audioRef={audioRef}
							handleTimestampClick={handleTimestampClick}
							search={search}
							setSearch={setSearch}
							filteredTimestamp={filterdTimestamp}
							setFilteredTimestamp={setFilteredTimestamp}
							loading={loading}
							showSelected={showSelected}
							setShowSelected={setShowSelected}
							audioMeta={audioMeta}
						/>
					</div>
				</div>
			</div>
			<>
				<p className="page_description">
					{" "}
					대표자 : 이은화 | 주소 : 경기도 시흥시 서울대학로 278번길 19-14
					배곧프라자 706호 | 평일 09시 ~ 18시 <br /> 연락처 : 1833-9413 | FAX
					070-8680-9413 | e-mail: chachodaema@gmail.com <br /> Copyright &copy;
					Daemacorp. All rights reserved
				</p>
				<button onClick={logOut}>Log out</button>
			</>
		</>
	);
};
export default NokcciPage;
