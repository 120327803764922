import React, { SetStateAction } from "react";
import { getRecord, getTranscript } from "../utils/fileUpload";
import type { AudioMeta, HistoryItem, SRT, User } from "../types/type";

const HistoryTab: React.FC<{
	setSrt: React.Dispatch<SetStateAction<SRT[]>>;
	history: HistoryItem[] | [];
	setAudioMeta: React.Dispatch<SetStateAction<AudioMeta | null>>;
	setAudioUrl: React.Dispatch<SetStateAction<string>>;
	user: User | null;
}> = ({ setSrt, history, setAudioMeta, setAudioUrl, user }) => {
	const groupByDate = (items: HistoryItem[]) => {
		if (items.length !== 0) {
			return items.reduce((groups, item) => {
				const date = item.date;
				if (!groups[date]) {
					groups[date] = [];
				}
				groups[date].push(item);
				return groups;
			}, {} as { [key: string]: HistoryItem[] });
		} else {
			return [];
		}
	};

	const renderHistoryContent = () => {
		if (history.length === 0) {
			return <div className="history"></div>;
		}

		const historyGroupedByDate = groupByDate(history);
		return Object.entries(historyGroupedByDate).map(([date, items]) => (
			<div className="date" key={date}>
				<h2>{date}</h2>
				{items.map((item, index) => (
					<div key={`${date}-${index}`} onClick={() => handleItemClick(item)}>
						<p>{item.title}</p>
					</div>
				))}
			</div>
		));
	};

	const handleItemClick = async (item: HistoryItem) => {
		const newAudioMeta: AudioMeta = {
			name: item.title,
			type: "",
		};
		setAudioMeta(newAudioMeta);
		try {
			const response = await getRecord(item, user);
			// Assuming response comes back with the transcript data in response.data
			if (response) {
				let srt = await getTranscript(response.transcript);
				setSrt(srt);
				setAudioUrl(response.nokcci);
			}
		} catch (error) {
			// Handle the error case by setting the SRT with an error message
			setSrt([
				{
					time: 0,
					label: "0:00",
					text: "녹취 파일을 여는데 실패하였습니다. 잠시 후 다시 한번 시도해 주세요",
					selected: false,
				},
			]);
		}
	};

	return <div className="history">{renderHistoryContent()}</div>;
};

export default HistoryTab;
