import React, { SetStateAction, useState } from "react";
import { useDropzone } from "react-dropzone";
import { uploadToNokcci } from "../utils/fileUpload";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Dropzone.module.css";
import { SRT, AudioMeta, User, HistoryItem } from "../types/type";

interface NokcciDropzoneProps {
	audioFile: string | null;
	setAudioFile: React.Dispatch<SetStateAction<string | null>>;
	audioMeta: AudioMeta | null;
	setAudioMeta: React.Dispatch<SetStateAction<AudioMeta | null>>;
	setSrt: React.Dispatch<SetStateAction<SRT[]>>;
	audioRef: React.RefObject<HTMLAudioElement>;
	setLoading: React.Dispatch<SetStateAction<boolean>>;
	audioUrl: string;
	setAudioUrl: React.Dispatch<SetStateAction<string>>;
	user: User | null;
	history: HistoryItem[];
	setHistory: React.Dispatch<SetStateAction<HistoryItem[]>>;
}

const NokcciDropzone: React.FC<NokcciDropzoneProps> = ({
	audioFile,
	setAudioFile,
	audioMeta,
	setAudioMeta,
	setSrt,
	audioRef,
	setLoading,
	audioUrl,
	setAudioUrl,
	user,
	history,
	setHistory,
}) => {
	const [error, setError] = useState("");
	const [isNormalSpeedClicked, setIsNormalSpeedClicked] = useState(false);
	const [isOnePointFiveSpeedClicked, setIsOnePointFiveSpeedClicked] =
		useState(false);
	const [isTwoSpeedClicked, setIsTwoSpeedClicked] = useState(false);

	const handleSpeedClick = (speed: number) => {
		if (audioRef.current) {
			audioRef.current.playbackRate = speed;
			setIsNormalSpeedClicked(speed === 1);
			setIsOnePointFiveSpeedClicked(speed === 1.5);
			setIsTwoSpeedClicked(speed === 2);
		}
	};
	const { getRootProps, getInputProps } = useDropzone({
		onDrop: async (acceptedFiles) => {
			try {
				setSrt([
					{
						time: 0,
						label: "0:00",
						text: "",
						selected: false,
					},
				]);
				setLoading(true);
				setError("");
				setAudioMeta({
					name: acceptedFiles[0].name,
					type: acceptedFiles[0].type,
				});

				// Perform the asynchronous upload operation
				let content = await uploadToNokcci(
					acceptedFiles as File[],
					user,
					history,
					setHistory
				);

				// Update state with the results
				setAudioUrl(content.audio);
				setSrt(content.srt);
			} catch (error) {
				// Type guard to assert the error is of type Error
				if (error instanceof Error) {
					// Now 'error' is type Error and has a 'message' property
					setError(error.message);
					console.error("Error occurred while uploading:", error.message);
				} else {
					// If it's not an Error instance, fallback error handling
					setError("An unexpected error occurred");
					console.error("An unexpected error occurred:", error);
				}
			} finally {
				// This block will run regardless of the error
				setLoading(false);
			}
		},
	});

	// const getAudio = (audioUrl:string) => {
	// 	let audiofile = axios.get(audioUrl);
	// }
	return (
		<>
			<div className="dropzone" {...getRootProps()}>
				<input {...getInputProps()} />
				{audioMeta ? (
					<div className="innerDiv">
						<h2>{audioMeta.name}</h2>
						<audio src={audioUrl} controls ref={audioRef} />
					</div>
				) : (
					<>
						<div className="iconC">
							<FontAwesomeIcon icon={faPlus} className="icon" />
							<p>
								<br />이 곳에 녹음 파일을 넣어주시면 AI가 텍스트로
								변환해드립니다.
							</p>
						</div>
						<div className="description">
							<p>
								변환 가능 파일은 mp3, mp4, mpeg, mpga, m4a, wav, webm 이며,{" "}
								<br /> 1회 업로드 파일의 용량은 25MB로 제한됩니다.
							</p>
						</div>
					</>
				)}
			</div>
			<div className="control">
				<button
					onClick={() => handleSpeedClick(1)}
					className={isNormalSpeedClicked ? "clicked" : "button"}
				>
					Normal speed
				</button>
				<button
					onClick={() => handleSpeedClick(1.5)}
					className={isOnePointFiveSpeedClicked ? "clicked" : "button"}
				>
					1.5x speed
				</button>
				<button
					onClick={() => handleSpeedClick(2)}
					className={isTwoSpeedClicked ? "clicked" : "button"}
				>
					2x speed
				</button>
			</div>
		</>
	);
};

export default NokcciDropzone;
